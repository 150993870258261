import { stAnalytics } from "@repo/analytics";
import { useNavigate } from "@solidjs/router";
import { TbClockFilled, TbTrash } from "solid-icons/tb";
import { type Component, For, Show, Suspense } from "solid-js";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { StandardGrid } from "~/components/containers/StandardGrid";
import { CreateCTA } from "~/components/cta/CreateCTA";
import { LargeChatCTA } from "~/components/cta/LargeChatCTA";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { SectionHeader } from "~/components/SectionHeader";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { useCreateNewSmartchatInCollection } from "~/domains/threads/hooks/useCreateNewSmartchatInCollection";
import type { ThreadSnapshot } from "~/domains/threads/threads.types";
import { getScreenType } from "~/lib/ui/getScreenType";
import { urls } from "~/lib/urls";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import { CollectionChatsDrawerScreen } from "../drawer/CollectionChatsDrawerScreen";
import { getRequestClient } from "@repo/client";
import { CustomEvents } from "~/ui/custom-events";
import { captureException } from "@repo/observability";
import { DeleteThreadModal } from "../modals/DeleteThreadModal";

export const CollectionChatsScreen: Component<{
  collection: CollectionSnapshot | undefined;
  threads: ThreadSnapshot[];
}> = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const state = useUIState();
  const [, setRightDrawer] = state.rightDrawer;
  const [, setRightDrawerContents] = state.rightDrawerContents;
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  const [, setLargeChatCTAContextMenu] = state.collectionThreadsContextMenu;

  const navigateToThread = (id: string) => navigate(urls.threadV2(id));

  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => props.collection?.id);

  const client = getRequestClient(wire.services.identity.getIdentityToken);

  const tombstone = async (id: string) => {
    try {
      await client.controlplane.TombstoneThreads({
        threadIds: [id],
      });
      CustomEvents.collectionThreadsUpdated.dispatch();
    } catch (error) {
      captureException(error);
      console.error(error);
    }
  };

  const onDeleteThread = (id: string, label: string) => {
    setModalOpen("delete-thread");
    setModalContents(() => () => (
      <DeleteThreadModal
        id={"edit-asset"}
        onDelete={async (): Promise<void> => {
          await tombstone(id);
          CustomEvents.collectionThreadsUpdated.dispatch();
          setModalOpen("");
          setModalContents(null);
        }}
        onClose={() => {
          setModalOpen("");
          setModalContents(null);
        }}
        label={label}
      />
    ));
  };

  return (
    <Suspense>
      <ContentContainer>
        <SectionHeader
          title="Recent Chats"
          icon={TbClockFilled}
          action={
            <Show when={props.threads.length}>
              <SecondaryCTA
                onClick={() => {
                  setRightDrawer(true);
                  setRightDrawerContents(() => () => (
                    <CollectionChatsDrawerScreen collectionId={props.collection?.id || ""} />
                  ));
                }}
                size="small"
                accessibleSuffix=" Chats"
                label="See all"
                data-test-id="see-all-chats"
              />
            </Show>
          }
        />
        <StandardGrid columns="four">
          <CreateCTA
            data-test-id="create-new-chat"
            onClick={() => {
              stAnalytics.track("click_tracking", {
                cta: "create_chat",
                position: "main_content",
                screen: getScreenType(),
              });
              return onCreateSmartchat();
            }}
            label="New SmartChat™"
            accessibleSuffix=""
          />
          <For each={props.threads.slice(0, 5)}>
            {(thread) => (
              <LargeChatCTA
                items={[
                  {
                    label: "Delete thread",
                    icon: TbTrash,
                    onClick: async () => {
                      onDeleteThread(thread.threadId, thread.label);
                      setLargeChatCTAContextMenu("");
                    },
                  },
                ]}
                data-test-id="upload"
                label={thread.label}
                onClick={() => {
                  stAnalytics.track("click_tracking", {
                    cta: "navigate_to_chat",
                    position: "main_content",
                    screen: getScreenType(),
                  });
                  return navigateToThread(thread.threadId);
                }}
                accessibleSuffix="files to this Collection."
                lastModified={thread.modifiedAt}
              />
            )}
          </For>
        </StandardGrid>
      </ContentContainer>
    </Suspense>
  );
};
