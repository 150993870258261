import { BsPersonFillX } from "solid-icons/bs";
import { createEffect, createSignal, onMount, Suspense } from "solid-js";
import type { CollectionsModalProps } from "~/components/containers/CollectionsModal";
import { DestructiveCTA } from "~/components/cta/DestruciveCTA";
import { InfoPanel } from "~/components/InfoPanel";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { useUIState } from "~/ui/UIState";
import styles from "./RemoveAccessModal.module.css";

interface Props extends CollectionsModalProps {
  identifier?: string;
  collectionName: string;
  onRevoke: () => Promise<void>;
}

export const RemoveAccessModal = (props: Props) => {
  const [modalOpen, setModalOpen] = useUIState().modal;
  const [, setFocus] = createSignal(false);
  let submitting = false;
  const onConfirm = async () => {
    if (!submitting) {
      submitting = true;
      props.onRevoke();
      setModalOpen("");
    }
  };

  createEffect(() => {
    if (modalOpen() === props.id) {
      setFocus(true);
    }
    if (modalOpen() === "") {
      setFocus(false);
    }
  });

  onMount(() => {
    submitting = false;
  });

  return (
    <Suspense>
      <Suspense>
        <p class={styles["remove-access-modal__title"]}>
          <span class={styles["remove-access-modal__title-text"]}>Confirm you want to continue.</span>
        </p>
        <InfoPanel
          title={"This action will revoke user acccess to a Collection."}
          messages={[`${formatCollectionName(props.collectionName)}`, `${props.identifier ?? ""}`]}
          subtext={["Please ensure you have reviewed your selection before continuing."]}
          icon={BsPersonFillX}
          variant="danger"
        />
        <div class={styles["remove-access-modal__buttons"]}>
          <div aria-hidden class={styles["remove-access-modal__buttons-left"]} />
          <div class={styles["remove-access-modal__buttons-right"]}>
            <DestructiveCTA
              data-test-id="remove-access-modal-submit"
              accessibleSuffix=""
              label="Remove Access"
              confirmLabel="Yes, remove access."
              type="button"
              icon={BsPersonFillX}
              onClick={onConfirm}
            />
          </div>
        </div>
      </Suspense>
    </Suspense>
  );
};
