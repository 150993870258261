import type { curator, operation } from "@repo/client";
import { TbFolderFilled } from "solid-icons/tb";
import {
  type Component,
  Show,
  type Signal,
  Suspense,
  createEffect,
  createSignal,
  on,
  onCleanup,
  onMount,
  startTransition,
} from "solid-js";
import { CollectionAssetsTable } from "~/components/CollectionAssetTable";
import { LargeFileUpload } from "~/components/LargeFileUpload";
import { Loading } from "~/components/Loading";
import { Pagination } from "~/components/Pagination";
import { SectionHeader } from "~/components/SectionHeader";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { ChatFileUpload } from "~/domains/chat/prompt/components/ChatFileUpload";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { createListResourceOptions } from "~/lib/api/createListResourceOptions";
import { parseAssetsListResponse } from "~/lib/api/parseAssetListResponse";
import { ProcessingReadableState, readableProcessingState } from "~/lib/ui/readableProcessingState";
import { useUIState } from "~/ui/UIState";

export const CollectionAssetsScreen: Component<{
  collection: CollectionSnapshot | undefined;
  assetResponse: operation.Response<curator.CollectionAssets> | undefined;
  refreshAssets: () => void;
  listOptions: Signal<ListResourceOptions>;
  highlightedAssetId?: string;
}> = (props) => {
  const state = useUIState();
  const [tab, setTab] = state.mainContentTab;
  const pageSignal = createSignal(1);
  const [page, setPage] = pageSignal;
  const [, setAssetsListOptions] = props.listOptions;
  createEffect(
    on(page, () => {
      startTransition(() => {
        setAssetsListOptions(createListResourceOptions(page()));
      });
    }),
  );

  const [isPolling, setIsPolling] = createSignal(false);
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let canPoll = false;

  const hasProcessingAssets = () =>
    parseAssetsListResponse(props.assetResponse).filter(
      (asset) =>
        readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Ready &&
        readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Failed,
    ).length > 0;

  const poll = () => {
    // Only proceed if we're allowed to poll and not already polling
    if (!canPoll || isPolling()) {
      return;
    }

    setIsPolling(true);

    if (hasProcessingAssets() && canPoll) {
      startTransition(() => {
        props.refreshAssets();
      });

      timeout = globalThis.setTimeout(() => {
        setIsPolling(false);
        poll();
      }, 3_000);
    } else {
      // Stop polling if no processing assets
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
      setIsPolling(false);
    }
  };

  // Monitor for processing assets in initial data or after updates
  createEffect(() => {
    if (tab() === "Assets" && props.assetResponse && !timeout && hasProcessingAssets()) {
      poll();
    }
  });

  onMount(() => {
    canPoll = true;
    if (hasProcessingAssets()) {
      poll();
    }

    onCleanup(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      canPoll = false;
      setIsPolling(false);
    });
  });

  const [assetUploadModal, setAssetUploadModal] = createSignal(false);
  return (
    <Suspense fallback={<Loading />}>
      <ChatFileUpload
        context="project"
        open={assetUploadModal()}
        setOpen={setAssetUploadModal}
        onUploaded={() => {
          startTransition(() => {
            props.refreshAssets();
          });
          globalThis.setTimeout(poll, 2000);
          setTab("Assets");
        }}
      />
      <ContentContainer>
        <SectionHeader title="Assets" icon={TbFolderFilled} />
        <LargeFileUpload showUploadModal={() => setAssetUploadModal(true)} />
      </ContentContainer>
      <Show when={parseAssetsListResponse(props.assetResponse).length}>
        <ContentContainer>
          <CollectionAssetsTable
            collectionName={props.collection?.label || ""}
            data={parseAssetsListResponse(props.assetResponse)}
            refreshAssets={props.refreshAssets}
            highlightedAssetId={props.highlightedAssetId}
          />
          <Show
            when={
              props.assetResponse?.data.pagination?.totalPages
                ? props.assetResponse?.data.pagination?.totalPages > 1
                : false
            }
          >
            <Pagination
              page={pageSignal}
              totalPages={props.assetResponse?.data.pagination.totalPages}
              totalCount={props.assetResponse?.data.pagination.count}
            />
          </Show>
        </ContentContainer>
      </Show>
    </Suspense>
  );
};
