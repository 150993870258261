import { stAnalytics } from "@repo/analytics";
import { BrowserStorage } from "@repo/storage";
import { FaSolidPeopleGroup } from "solid-icons/fa";
import { PrimaryCTAAnchor } from "~/components/cta/PrimaryCTAAnchor";
import { StIcon } from "~/components/icons";
import styles from "./RegistrationRequiredScreen.module.css";

export const RegistrationRequiredScreen = () => {
  return (
    <div class={styles["registration-required"]}>
      <div class={styles["registration-required__container"]}>
        <div class={styles["registration-required__header"]}>
          <StIcon icon={FaSolidPeopleGroup} size="5rem" class={styles["registration-required__icon"]} />
        </div>
        <div class={styles["registration-required__content"]}>
          <p class={styles["registration-required__title"]}>Looking to Collaborate?</p>
          <p class={styles["registration-required__subtitle"]}>
            Sharing Collections with others requires a free account.
          </p>
          <div class={styles["registration-required__actions"]}>
            <PrimaryCTAAnchor
              href="/auth/signup"
              label="Sign up today and start sharing."
              accessibleSuffix="for an account."
              data-test-id="collections-signup"
              onClick={() => {
                stAnalytics.track("sign_up_clicked", { screen: "sharing" });
                BrowserStorage.setSignupReferrerUrl(location.pathname);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
