import { AssetLifecycleStates, type assets as assetsNS } from "@repo/client";
import { BsPersonFillLock } from "solid-icons/bs";
import { TbPlus } from "solid-icons/tb";
import {
  type Component,
  createEffect,
  For,
  Match,
  on,
  onCleanup,
  onMount,
  startTransition,
  Suspense,
  Switch,
} from "solid-js";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { StIcon } from "~/components/icons";
import { InlineAsset } from "~/components/InlineAsset";
import { LargeFileUpload } from "~/components/LargeFileUpload";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import styles from "./GettingStarted.module.css";
import { originalFilenameToMimeType } from "~/lib/originalFilenameToMimeType";

export const GettingStartedUpload: Component<{ assets: assetsNS.AssetSnapshot[]; refetch: () => void }> = (props) => {
  const { setShowUploadModal } = usePromptContext();
  const hasAssets = () => !!props.assets.length;

  const label = () =>
    hasAssets() ? `You have ${props.assets.length} assets in your` : "These will be first saved in the selected";

  let refetching = false;
  let timeoutRef: NodeJS.Timeout | undefined;
  const intervalRefetch = () => {
    if (props.assets.every((a) => a.lifecycleState === AssetLifecycleStates.Ready)) {
      refetching = false;
      return;
    }
    startTransition(() => {
      props.refetch();
    });
    timeoutRef = setTimeout(() => {
      intervalRefetch();
    }, 3000);
  };
  createEffect(
    on(
      () => props.assets.length,
      () => {
        if (props.assets.length > 0) {
          if (!refetching) {
            refetching = true;
            intervalRefetch();
          }
        }
      },
    ),
  );

  onMount(() => {
    onCleanup(() => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    });
  });

  return (
    <Suspense>
      <div class={styles["getting-started"]}>
        <p class={styles["getting-started__step"]}>
          <span class={styles["getting-started__step-text"]}>1.</span>
        </p>
        <p class={styles["getting-started__heading"]}>
          <span class={styles["getting-started__heading-title"]}>Add assets.</span>
          <span class={styles["getting-started__heading-subtitle"]}>
            <span class={styles["getting-started__heading-text"]}>{label()}</span>
            <span class={styles["getting-started__accordion"]}>
              <StIcon class={styles["getting-started__accordion-icon"]} icon={BsPersonFillLock} size="1.5rem" />
              <span class={styles["getting-started__accordion-text"]}>Personal Collection</span>
            </span>
          </span>
        </p>
        <div class={styles["getting-started__actions"]}>
          <Switch>
            <Match when={hasAssets()}>
              <div>
                <For each={props.assets}>
                  {(asset) => (
                    <InlineAsset
                      class={styles["getting-started__asset"]}
                      inline
                      name={asset.displayName || asset.originalFilename}
                      mimeType={originalFilenameToMimeType(asset.originalFilename)}
                      status={asset.lifecycleState}
                      lastModified={asset.modifiedAt}
                    />
                  )}
                </For>
                <SecondaryCTA
                  onClick={() => setShowUploadModal(true)}
                  label="Add more"
                  accessiblePrefix=""
                  accessibleSuffix=" assets."
                  icon={TbPlus}
                  data-test-id="add-more-assets"
                />
              </div>
            </Match>
            <Match when={true}>
              <LargeFileUpload hideLabel solid showUploadModal={() => setShowUploadModal(true)} />
            </Match>
          </Switch>
        </div>
      </div>
    </Suspense>
  );
};
