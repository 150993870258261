import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { TbDots, TbMessage } from "solid-icons/tb";
import { createSignal, createUniqueId, Show } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./LargeChatCTA.module.css";
import type { CTAProps } from "./types";
import { Portal } from "solid-js/web";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import { ContextMenu } from "../context-menu/ContextMenu";
import type { ContextMenuItemProps } from "../context-menu/ContextMenuItem";
import { StIcon } from "../icons";
import { IconCTA } from "./IconCTA";
import { useUIState } from "~/ui/UIState";

// Initialize the relative time plugin
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

interface ButtonProps extends CTAProps {
  label: string;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
  lastModified?: string;
  modifier?: "surface";
  items?: ContextMenuItemProps[];
}

type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

const formatLastModified = (timestamp: string) => {
  const now = dayjs();
  const modified = dayjs(timestamp);
  const hoursDiff = now.diff(modified, "hour");

  // If less than 24 hours ago, use relative time
  if (hoursDiff < 24) {
    return modified.fromNow(); //  "5 minutes ago", "2 hours ago"
  }

  return modified.format("LL");
};

export const LargeChatCTA = (props: Props) => {
  const state = useUIState();
  const signal = state.collectionThreadsContextMenu;
  const [ref, setRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const id = `large-chat-cta-context-menu-${createUniqueId()}`;

  return (
    <div class="relative h-full">
      <button
        type="button"
        class={classNames(
          styles["large-chat-cta"],
          props.class,
          props.modifier ? styles[`large-chat-cta--${props.modifier}`] : "",
        )}
        tabIndex={0}
        on:click={props.onClick}
      >
        <span class={styles["large-chat-cta__icon-container"]}>
          <TbMessage class={styles["large-chat-cta__icon"]} size="1.25rem" />
        </span>
        <Show when={props.accessiblePrefix}>
          <span class="screen-reader">{props.accessiblePrefix}</span>
        </Show>
        <span class={styles["large-chat-cta__label"]}>{props.label}</span>
        <Show when={props.accessibleSuffix}>
          <span class="screen-reader">{props.accessibleSuffix}</span>
        </Show>
        <Show when={props.lastModified}>
          <span class="screen-reader">Last updated</span>
          <span class={styles["large-chat-cta__age"]}>{formatLastModified(props.lastModified!)}</span>
        </Show>
      </button>

      <Show when={props.items}>
        <div class="absolute top-0 right-0" ref={setRef}>
          <IconCTA
            icon={TbDots}
            modifiers={["small"]}
            aria-controls={id}
            aria-haspopup="true"
            accessibleLabel="Open context menu"
            data-test-id="large-chat-cta-context-menu"
          />
        </div>

        <Portal mount={document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}>
          <ContextMenu
            class="fixed"
            id={id}
            items={props.items ?? []}
            signal={signal}
            placement="bottom-start"
            bound={ref()}
          />
        </Portal>
      </Show>
    </div>
  );
};
