import { getRequestClient } from "@repo/client";
import { TbClipboardCheck, TbClipboardCopy } from "solid-icons/tb";
import { type Component, For, Show, Suspense, createSignal, startTransition } from "solid-js";
import { EditUserPermissions, type EditUserPermissionsOptions } from "~/components/EditUserPermissions";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { type UsersTagListTag, UsersTagsList } from "~/components/fields/UsersTagsList";
import type { CollectionAccessRecord } from "~/domains/collections/collections.types";
import type { AuthenticatedIdentity } from "~/domains/identity/types";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { urls } from "~/lib/urls";
import { useUIState } from "~/ui/UIState";
import { CustomEvents } from "~/ui/custom-events";
import { useWire } from "~/wire";
import { RemoveAccessModal } from "../modals/RemoveAccessModal";
import styles from "./PermissionsDrawerScreen.module.css";

export const PermissionsDrawerScreen: Component<{
  collectionId: string;
  access?: CollectionAccessRecord[];
}> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.identity.getIdentityToken);
  const collection = () => wire.services.collections.getCollection(props.collectionId);

  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;

  const sendInvites = async (tags: UsersTagListTag[]) => {
    await client.controlplane.ShareCollection({
      collectionIds: [props.collectionId],
      shareTo: tags.filter((t) => !!t.avatar).map((t) => t.id),
      shareToEmails: tags.filter((t) => t.email).map((t) => t.id),
      actions: ["collection:read"],
    });

    startTransition(() => {
      CustomEvents.collectionPermissionChange.dispatch();
    });
  };

  const options: EditUserPermissionsOptions = [{ value: "collection:read", label: "Viewer" }];

  const revokeInvite = async (token: string) => {
    await client.controlplane.DeleteToken(token);
    startTransition(() => {
      CustomEvents.collectionPermissionChange.dispatch();
    });
  };

  const revokeAccess = (userId: string, collectionId: string) => async () => {
    await wire.services.collections.apiModifyCollectionUserPermissions({
      collectionId,
      userId,
      actions: [],
    });
    CustomEvents.collectionPermissionChange.dispatch();
  };

  const isItemYou = (item: CollectionAccessRecord) =>
    item.user_id === (wire.services.identity.snapshot.context.identity as AuthenticatedIdentity)?.userId;
  const itemName = (item: CollectionAccessRecord) => (item.sortableName === "Guest" ? item.email : item.sortableName);
  const withAccess = () =>
    props.access?.filter((r) => r.accessType === "user").sort((a, b) => (isItemYou(a) ? -1 : isItemYou(b) ? 1 : 0));

  return (
    <Suspense>
      <div>
        <p class={styles["permissions-drawer__title"]}>Collaboration</p>

        <Show when={wire.services.collections.getCanShareCollection(props.collectionId)}>
          <ContentContainer modifier="wide">
            <p class={styles["permissions-drawer__section-title"]}>
              Invite users {collection()?.label ? ` to the ${formatCollectionName(collection()?.label)}` : ""}
            </p>
            <UsersTagsList
              sendInvites={sendInvites}
              options={options}
              access={props.access?.filter((a) => a.collection_id === props.collectionId)}
            />
          </ContentContainer>
        </Show>

        <Show when={props.access?.filter((r) => r.accessType === "token").length}>
          <ContentContainer modifier="wide">
            <p class={styles["permissions-drawer__section-title"]}>Pending invites</p>
            <For each={props.access?.filter((r) => r.accessType === "token")}>
              {(item) => (
                <EditUserPermissions
                  avatar={{
                    name: itemName(item),
                    size: "inline",
                    initial: itemName(item).substring(0, 1),
                  }}
                  isYou={isItemYou(item)}
                  disabled
                  value={item.actions[0]}
                  options={options}
                  inherits={
                    item.collection_id !== collection()?.id
                      ? {
                          collectionId: item.collection_id,
                          label: item.collectionLabel,
                        }
                      : undefined
                  }
                  actions={
                    <>
                      <SecondaryCTA
                        size="small"
                        data-test-id="revoke-invite"
                        accessiblePrefix="Click to "
                        label="Revoke"
                        accessibleSuffix=" invite"
                        onClick={() => revokeInvite(item.token)}
                      />
                      <CopyInviteButton token={item.publicToken} />
                    </>
                  }
                />
              )}
            </For>
          </ContentContainer>
        </Show>

        <Show when={withAccess()?.length}>
          <ContentContainer modifier="wide">
            <p class={styles["permissions-drawer__section-title"]}>Current users</p>
            <For each={withAccess()}>
              {(item) => (
                <EditUserPermissions
                  avatar={{
                    name: itemName(item),
                    size: "inline",
                    isGuest: item.sortableName === "Guest",
                  }}
                  disabled
                  isYou={isItemYou(item)}
                  value={item.actions[0]}
                  options={options}
                  inherits={
                    item.collection_id !== collection()?.id
                      ? {
                          collectionId: item.collection_id,
                          label: item.collectionLabel,
                        }
                      : undefined
                  }
                  actions={
                    <Show
                      when={
                        item.user_id !==
                        (wire.services.identity.snapshot.context.identity as AuthenticatedIdentity)?.userId
                      }
                    >
                      <SecondaryCTA
                        size="small"
                        data-test-id="revoke-invite"
                        accessiblePrefix="Click to "
                        label="Remove"
                        accessibleSuffix=" from collection"
                        onClick={() => {
                          setModalContents(() => () => (
                            <RemoveAccessModal
                              id="remove-access-modal"
                              identifier={itemName(item)}
                              collectionName={item.collectionLabel}
                              onRevoke={revokeAccess(item.user_id, item.collection_id)}
                            />
                          ));
                          setModalOpen("remove-access-modal");
                        }}
                      />
                    </Show>
                  }
                />
              )}
            </For>
          </ContentContainer>
        </Show>
      </div>
      {/* <form ref={(ref) => setForm(ref)} class={styles["permissions-drawer"]} onSubmit={onSubmit}>
        <p class={styles["permissions-drawer__title"]}>Manage permissions</p>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Add new users by email</p>
          <LargeInputText
            placeholder="Enter a user email"
            label="Name your new Collection"
            name="user-email"
            validation={["email"]}
            value=""
            class={styles["permissions-drawer"]}
            errorMessage={data()?.messages.name}
            modifier="outline"
            size="tight"
          />
        </ContentContainer>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Pending invite</p>
          <EditUserPermissions
            avatar={{
              name: "paul.atreides@dune.com",
              size: "inline",
              initial: "paul.atreides@dune.com".substring(0, 1),
            }}
          />
        </ContentContainer>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Full access</p>
          <For each={mockdata}>
            {(item: MockData) => (
              <EditUserPermissions
                avatar={{
                  name: item.owner,
                  size: "inline",
                  initial: item.owner?.substring(0, 1),
                }}
                value="1"
              />
            )}
          </For>
        </ContentContainer>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Collaborator access</p>
          <For each={mockdata}>
            {(item: MockData) => (
              <EditUserPermissions
                avatar={{
                  name: item.owner,
                  size: "inline",
                  initial: item.owner?.substring(0, 1),
                }}
                value="2"
              />
            )}
          </For>
        </ContentContainer>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Viewer access</p>
          <For each={mockdata}>
            {(item: MockData) => (
              <EditUserPermissions
                avatar={{
                  name: item.owner,
                  size: "inline",
                  initial: item.owner?.substring(0, 1),
                }}
                value="3"
              />
            )}
          </For>
        </ContentContainer>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>No access</p>
          <For each={mockdata}>
            {(item: MockData) => (
              <EditUserPermissions
                avatar={{
                  name: item.owner,
                  size: "inline",
                  initial: item.owner?.substring(0, 1),
                }}
              />
            )}
          </For>
        </ContentContainer>
        <div class={styles["permissions-drawer__buttons"]}>
          <PrimaryCTA
            data-test-id="create-collections-modal-create"
            accessibleSuffix=""
            label="Update permissions"
            type="submit"
            rounded
            inactive={!canSubmit()}
          />
        </div>
      </form> */}
    </Suspense>
  );
};

const CopyInviteButton: Component<{ token: string }> = (props) => {
  const link = (token: string) => `${window.location.origin}${urls.acceptInvite(token)}`;
  const [copied, setCopied] = createSignal(false);

  const copy = () => {
    navigator.clipboard.writeText(link(props.token));
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <PrimaryCTA
      onClick={copy}
      icon={copied() ? TbClipboardCheck : TbClipboardCopy}
      size="small"
      data-test-id="copy-invite"
      accessiblePrefix="Click to "
      label={copied() ? "Copied!" : "Copy invite"}
    />
  );
};
